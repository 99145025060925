.left {
    min-height: 100%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
    background-image: url(../../../src/Images/Heads/head-left.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
}

.left .welcome-text{
    font-family: FSAlbertProBoldExtra;
    font-size: 50px;
    line-height: 40px;
    /* or 116% */
    text-align: center;
    letter-spacing: 6.42667px;
    text-transform: uppercase;
    color: #000000;
}

.left .login-text{
    min-height: 62px;
    font-family: FSAlbertPro;
    font-size: 24px;
    line-height: 31px;
    /* or 155% */
    text-align: center;
    letter-spacing: -0.48px;
}
.left .card-container{
    display: flex;
    flex-direction: column;
}
.left .card-container span{
    margin: 0 auto;
    font-size: 24px;
    font-family: FSAlbertPro;
}

.left .card-container .card{
    position: relative;
    width: 60%;
    max-width: 400px;
    margin: 0 auto;
    border-radius: 15px;
    background-color: #FDB931;
}

@media (min-width: 1030px){
    .left .welcome-text{
        width: 70%;
        margin: 0 auto;
    }
    .left .login-text{
        width: 70%;
        margin: 0 auto;
    }
}

@media (max-width: 992px){
    .left{
        display:none;
        visibility:collapse;
        height: 0px;
    }
}
