.securirtypage-container{
    position: relative;
    width: 100%;
    height: 100%;
    background-color: transparent;
}

.change-page-container{
    width: calc(100%);
}

.securirtypage-container .security-fields-container{
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 300px;
    padding: 30px;
    margin-top: 20px;
    background-color:#fff;
    border-radius: 15px;
}

.password-container-security{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    left: 0px;
    top: 0px;
    width: 100%;
    padding: 30px;
    min-height: 410px;
    background-color: #fff;
    z-index: 0;
    border-radius: 15px;
    margin-top: 20px;
}

@media (max-width: 1370px) {
    .securirtypage-container{
        width: calc(100% - 45px);
    }
    
    .password-container-security{
        width: calc(100% - 45px);
    }
}

@media (max-width: 914px) {
    .securirtypage-container{
        width: calc(100%);
    }

    .change-page-container{
        width: calc(100%);
    }

    .password-container-security{
        width: calc(100%);
    }
}

.password-container-security > div{
    width: 350px;
    height: clamp(75px, 14vmin, 96px);
}


.password-container-security .save-changes-btn{
    height: 51px;
    width: 200px;
}

@media (max-width: 760px) {
    .password-container-security > div{
        width: 300px;
    }
}

.header-backimg-container{
    display: flex;
    flex-direction: row;
    align-items: center;
}