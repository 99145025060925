.phone-container{
    width: 100%;
    min-width: 300px;
    height: clamp(75px, 14vmin, 96px);
}

.birthday-container{
    display: flex;
    flex-direction: column;
    width: 100%;
    min-width: 300px;
    height: clamp(75px, 14vmin, 96px);
}

.birthday-container .birthday-dropdowns-container{
    display: flex;
    width: 100%;
    height: clamp(40px, 7vmin, 60px);
    justify-content: space-between;
}

.birthday-container .birthday-dropdowns-container .birthday-dropdown-extra-styling{
    border-radius: 10px;
    width: 30%;
    min-width: 80px;
}

.name-container{   
    width: 100%;
    min-width: 300px;
    height: auto;
}

.additional-info{
    margin-bottom: 30px;
    min-width: 300px;
    font-family: 'FSAlbertPro';
    font-size: 22px;
}

.additional-info img{
    position: relative;
    top: -1px;
    left: 10px;
    width: 22px;
    height: 22px;
    cursor: pointer;
}

.signup-info-popup{
    position: fixed;
    left: 50%;
    top: 50%;
    width: 50vw !important;
    min-width: 300px !important;
    transform: translate(-50%, -50%);
    background-color: #f4f4ea;
    z-index: 12;
    padding: 0px 15px 20px 15px;
    border-radius: 12px;
}

.signup-info-popup .header{
    position: relative;
    display: flex;
    justify-content: center;
}

.signup-info-popup .header .popup-title{
    justify-self: center;
    font-family: 'FSAlbertProBold';
    font-size: 30px;
}

.signup-info-popup .header button{
    position: absolute;
    font-size: 30px;
    right: 0;
    top: 0;
    background-color: transparent;
    border: none;
}