.right{
    margin: 0;
}

.slideshow-container-main{
    position: relative;
}

.email-container{
    width: 100%;
    height: clamp(75px, 14vmin, 96px);
}

.pw-container{
    width: 100%;
    height: clamp(75px, 14vmin, 96px);
}

.continue-btn-container{
    width: 100%;
    height: clamp(65px, 14vmin, 96px);
}

.info-popup-x{
    display: flex;
    padding: 0 20px;
    align-items: center;
    justify-content: space-between;
    color: #fff;
    font-family: 'FSAlbertPro';
    font-size: 18px;
    width: 100%;
    height: 60px;
    border-radius: 13px;
    opacity: 0;
    transition: 0.5s;
    background-color: #a0cf67;
}

@media (max-width: 515px) {
    .info-popup-x{
        font-size: 14px;
    }
}