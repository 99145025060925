.cardpage-container{
    display: flex;
    position: relative;
    flex-direction: row;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    background-color: #F5F7F0;
    padding-bottom: 20px;
}

.cardpage-container .navigationbar-container{
    position: relative;
    z-index: 2;
    transition: 0.5s;
    /* left: -85px; */
}

@media (max-width: 914px) {
    .cardpage-container .navigationbar-container{
        position: absolute;
        left: -85px;
        height: 100%;
    }
}

.cardpage-container .navigationbar-container-active{
    left: 0px;
}

.main2{
    display: flex;
    flex-direction: column;
    width: calc(100% - 85px);
    background-color: #F5F7F0;
    align-items: stretch;
    justify-content: stretch;
}

.main2 .links-container{
    display: flex;
    flex-direction: row;
    min-height: 110px;
    width: calc(100% - 130px);
    margin:30px 0px;
    padding-left: 45px;
    box-sizing: border-box;
}

.main2 .component-container{
    width: calc(100% - 130px);
    padding-left: 45px;
    margin-top: 45px;
    border-radius: 15px;
    box-sizing: border-box;
}

@media (max-width: 1370px) {
    .main2 .links-container{
        width: 100%;
    }

    .main2 .component-container{
        width: 100%;
    }
}

@media (max-width: 914px) {
    .main2 {
        width: 100%;
    }

    .main2 .component-container{
        padding: 0 10px;
    }

    .main2 .links-container{
        padding-left: 0px;
    }
}
/* 
@media (max-width: 974px) {
    .main2 .links-container{
        padding: 0px 1%;
    }
    .main2 .component-container{
        padding: 0px 1%;
    }
} */


.card-promt{
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    top: 50vh;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 30%;
    padding: 0 20px;
    min-width: 350px;
    height: 250px;
    background: #fff;
    border-radius: 15px;
    z-index: 10;
}

.card-promt .promt-message{
    width: 100%;
    font-family: FSAlbertPro;
    font-size: 1.2rem;  
    text-align: center;
}

.card-promt .btns-container{
    display: flex;
    justify-content: space-around;
    width: 80%;
}

.card-promt .btns-container .confirm{
    display: flex;
    width: 80px;
    height: 40px;
    background: #FDB913;
    border-radius: 10px;
    cursor: pointer;
}

.card-promt .btns-container .confirm span{
    font-family: FSAlbertProBold;
    margin: auto;
    font-size: 1rem;
}

.card-promt .btns-container .deny{
    display: flex;
    width: 100px;
    height: 40px;
    background: #000;
    cursor: pointer;
    border-radius: 10px;
}

.card-promt .btns-container .deny span{
    font-family: FSAlbertProBold;
    margin: auto;
    font-size: 1rem;
    color: #FDB913
}