html{
    background-color: #F5F7F0;
}

.modal-container{
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content:space-between;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 1200px;
    min-height: 600px;
    max-width: 90%;
    background-color: #fff;
    border-radius: 15px;
    z-index: 12;
    padding: 20px 40px;
}

.modal-container .close-btn{
    position: absolute;
    right: 10px;
    top: 0;
    background-color: #fff;
    outline: none;
    border: none;
    font-weight: bold;
    font-size: 2rem;
}

.modal-container .header{
    display: flex;
    justify-content: space-between;
    width: 100%;
    min-height: 300px;
}

.modal-container .header .title-container{
    display: flex;
    flex-direction: column;
    margin-top: 40px;
}
.modal-container .header .title-container .title1{
    font-family: FSAlbertProBold;
    font-size: 42px;
    cursor: default;
}

.modal-container .header .title-container .subtitle1{
    font-family: FSalberPro;
    font-size: 16px;
    color: #ACB6C5;
    cursor: default;
}

.modal-container .header .card-container{
    position: relative;
    right:0;
    min-width: 250px;
    margin: auto 0;
    border-radius: 15px;
    overflow: hidden;
}

.modal-container .header .card-logo{
    position: relative;
    right:0;
    width: 320px;
    max-width: 100%;
    margin: auto 0;
}

.modal-container .body .input-container{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.modal-container .body .input-container input{
    background-color: transparent;
    outline: none;
    border: none;
    border-bottom: 1px solid #FDB913;
    width: 100px;
    text-align: center;
}

.modal-container .body .input-container .show-pin{
    display: flex;
    width: 300px;
    height: 40px;
    border: 2px solid #FDB913;
    background-color: #fff;
    border-radius: 13px;
    margin: 30px 0px;
    cursor: pointer;
}

.modal-container .body .input-container .reset-pin{
    display: flex;
    width: 300px;
    height: 40px;
    border: none;
    background-color: #FDB913;
    border-radius: 13px;
    cursor: pointer;
}

.modal-container .body .input-container span{
    margin: auto;
    font-size: 20px;
    font-family: FSAlbertPro;
}

#firstCard{
    margin-right: 10px;
}

@media (max-width: 1120px) {
    

    .modal-container .header .title-container{
        text-align: center;
    }

    .modal-container .header{
        flex-direction: column;
        align-items: center;
    }

    .modal-container .header .card-logo{
        margin: 10px 0;
    }
}

@media (max-width: 811px) {
    #firstCard{
        margin-right: 0px;
    }
    
    .header .card-container{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

.modal-container .continue-btn{
    align-self: center;
    height: clamp(40px, 8vmin, 72px);
    width: 60%;
    min-width: 300px;
    margin-top: 10px;
}