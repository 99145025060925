
.continue-container{
    display: flex;
    position: relative;
    cursor: pointer;
    border-radius: 15px;
    border: 0px;
    height: clamp(40px, 7vmin, 60px);
    width: 100%;
    padding: 0 10px;
    background: #FDB913;
    border-radius: 13px;
    text-decoration: none;
}

.continue-container .txt{
    margin: auto;
    font-family: FSAlbertProBold;
    font-size: 24px;
    line-height: 19px;
    /* identical to box height, or 106% */
    color: #000;
    text-decoration: none;
}


@media (max-width: 760px) {
    .continue-container .txt{
        font-size: 20px;
    }   
}
    .disabled{
    background: #dfcea3 !important;
    cursor: default !important;
}

.gold{
    color: #FDB913; 
}


.buttons{
    width: 100%;
    min-width: 300px;
    align-self: center;
    height: clamp(40px, 8vmin, 56px);
    font-size: 24px;
    background-color: transparent;
}

@media (max-width: 760px) {
    .buttons{
        font-size: 20px;
    }
}

.buttons > div{
    width: 40%;
} 

.btn-back{
    width: 100%;
    height: 100%;
    min-width: 75px;
    border: 1.5px solid #FDB913;
    box-sizing: border-box;
    border-radius: 13px;
    cursor: pointer;

    font-family: FSAlbertProBold;
    line-height: 19px;

    /* identical to box height, or 106% */
    background-color: transparent;
    color: #FDB913;
}

.btn-continue{
    width: 100%;
    height: 100%;
    min-width: 100px;
    border: 0px;
    background: #FDB913;
    border-radius: 13px;
    cursor: pointer;
    font-family: FSAlbertProBold;
    line-height: 19px;
    /* identical to box height, or 106% */
    color: #000;
}

.large-container{
    position: relative;
    width: 100%;
    height: clamp(75px, 14vmin, 96px);
    box-sizing: border-box;
}

.large-container .view-pw{
    position: absolute;
    width: 18px;
    height: 12px;
    bottom: clamp(13px, 2.5vmin, 22px);
    right: 20px;
    cursor: pointer;
}

.from-password-helper{
    height: 100%;
}

.large-container input{
    width: 100%;
    height: clamp(40px, 7vmin, 60px);
    border: 0px;
    box-sizing: border-box;
    border-radius: 13px;
    padding: 0 18px;
    font-family: FSAlbertPro;
    font-size: 20px;
    line-height: 19px;
    background: #FEF4EA;
    border: 1px solid #FDB913;
}

.large-container input::placeholder{
    font-family: FSAlbertPro;
    font-size: 20px;
    line-height: 19px;
    /* identical to box height, or 106% */
    color: #c9cacc;
    opacity: 1;
    padding-right: 25px;
}

.large-container input:focus{
    outline: 1px solid #000;
    border: 0px;
}

.large-container .up-text{
    font-family: FSAlbertPro;
    font-size: 24px;
    line-height: 19px;
    /* identical to box height, or 106% */
    color: #1A1919;
    cursor: default;
}

.error-message{
    font-family: FSAlbertProBold;
    color: red;
}

.double-container{
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: space-between;
}

.double-container .left-input{
    width: 48%;
}

.double-container .right-input{
    width: 48%;
}

@media (max-width: 768px) {
    .double-container{
        flex-direction: column;
    }

    .double-container .left-input{
        width: 100%;
    }

    .double-container .right-input{
        width: 100%;
    }
}

.menuitem-container{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    text-align: center;
    border-radius: 10px;
    cursor: pointer;
}

.menuitem-container .nav-icon-container{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 56px;
    height: 56px;
    border-radius: 10px;
}

.menuitem-container .nav-icon-container:hover{
    background: #FEF4EA;
}

.menuitem-container .text{
    font-family: FSAlbertPro;
    font-size: 12px;
    line-height: 19px;
    text-align: center;
    cursor: default;
    color: #323232;
    /* identical to box height, or 158% */
}

.menuitem-row-container{
    position: relative;
    display: flex;
    align-items: center;
    width: 90%;
    height: 65px;
    margin: 15px 15px;
    border-radius: 10px;
    cursor: pointer;
}

.menuitem-row-container:hover{
    background-color: #FEF4EA;
}

.menuitem-row-container .nav-icon-container{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    border-radius: 10px;
    background-color: #FEF4EA;
}

.menuitem-row-container .nav-icon-container img{
    margin: auto;
}

.menuitem-row-container .text-container{
    position: relative;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #FEF4EA;
    height: 100%;
    width: auto;
    padding-left: 15px;
    font-family: FSAlbertPro;
    font-size: 15px;
    cursor: pointer;
}

.arrowInput-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    width: 100%;
    height: 59px;
    margin: 10px 0px;
    border-bottom: 1px solid #FEF4EA;
    cursor:pointer;
}

.arrowInput-container span{
    font-family: FSAlbertPro;
    font-size: 20px;
    line-height: 19px;
    /* identical to box height, or 106% */
    color: #323232;
}

.newInput-container{
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 20px 30px;
}

.newInput-container .text-top{
    font-family: FSAlbertPro;
    min-width: 122px;
    font-size: 24px;
}

.newInput-container input{
    height: clamp(40px, 7vmin, 60px);
    width: 40%;
    min-width: 300px;
    padding: 0px 20px;
    box-shadow: none;
    border-radius: 10px;
    font-family: FSAlbertPro;
    font-size: 24px;
    /* identical to box height, or 106% */
    color: #323232;
    background: #FEF4EA;
    border: 0px;
}

.newInput-container input::placeholder{
    font-family: FSAlbertPro;
    font-size: 24px;
    line-height: 19px;
    /* identical to box height, or 106% */
    color: #323232;
}

.newInput-container input:focus{
    outline: 1px solid #FDB913;
}

@media (max-width: 760px) {
    .newInput-container input::placeholder{
        font-size: 16px;
    }

    .newInput-container input{
        font-size: 16px;
    }

    .newInput-container .text-top{
        font-size: 20px;    
    }
}

.goldenbtn-container{
    width: 100%;
    height: 100%;
    border-radius: 10px;
    border: 0px;
    background-color: #FDB913;
}

.goldenbtn-container span{
    font-family: FSAlbertProBold;
    font-size: 20px;
}

.icon-text-btn-container{
    display: flex;
    position: relative;
    align-items: center;
    border-radius: 10px;
    cursor: pointer;
    height: 100%;
    width: 100%;
}

.icon-text-btn-container .text{
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    margin: 0 10px;
    border-bottom: 1px solid #FEF4EA;
    border-top: 1px solid #FEF4EA;
}

/* .icon-text-btn-container:hover{
    background-color: #FEF4EA;
} */

.icon-text-btn-container .text span{
    font-family: FSAlbertPro;
}

@media (max-width: 760px) {
    .large-container .up-text{
        font-size: 20px;
    }

    .large-container input::placeholder{
        font-size: 16px;
    }

    .large-container input{
        font-size: 16px;
    }
}