.bank-info-page{
    position: relative;
    width: calc(100%);
    min-height: 250px;
}

.bank-info-page .bank-info-container{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 20px;
    padding: 30px;
    min-height: 350px;
    min-width: 335px;
    border-radius: 15px;
    background-color: #fff;
}

.title{
    font-size: 24px;
    font-family: FSAlbertPro;
}

.bank-info-input-container{
    display: flex;
    padding: 0 5px;
    margin-top: 5px;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px solid black;
}

.bank-info-input-container span{
    font-size: 20px;
    font-family: FSAlbertPro;
}

.bank-info-input-container img{
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.info-popup{
    position: fixed;
    visibility: hidden;
    opacity: 0;
    background-color: #a0cf69;
    border-radius: 15px;
    max-width: calc(100vw - 40px);
    right: 20px;
    padding: 15px 10px;
    transition: 0.5s;
    bottom: 20px;
    color: #fff;
}

.info-popup-active{
    opacity: 1;
    visibility: visible;
}

@media (max-width: 1370px) {
    .bank-info-page{
        width: calc(100% - 45px);
    }
}

@media (max-width: 914px) {
    .bank-info-page{
        width: 100%;
    }
}
