.faqpage-container{
    position: relative;
    height: 100%;
    max-width: calc(100%);
}

@media (max-width: 1370px) {
    .faqpage-container{
        width: calc(100% - 45px);
    }
}

@media (max-width: 914px) {
    .faqpage-container{
        width: 100%;
    }
}

.faqpage-container .questions-container{
    position: relative;
    height: auto;
    min-height: 200px;
    width: 100%;
    margin-top: 20px;
    border-radius: 15px;
    background-color: #fff;
    padding: 30px;
}