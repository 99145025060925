.modal-container .body .card-settings-input-group{
    min-width: 300px;
}

.slider-title{
    font-family: FSAlbertPro;
    font-size: 24px;
    line-height: 19px;
    color: #1A1919;
    cursor: default;
}

.slider-container .limits-indicator{
    font-family: FSAlbertPro;
    font-size: 20px;
    margin-bottom: 5px;
}

.slider-body{
    display: flex;
    align-items: center;
    background-color: #fef4ea;
    border-radius: 13px;
    padding: 0 5px;
    height: 60px;
    margin-top: 5px;
}

.slider-body .slider{
    width: 100%;
}

.card-settings-container .double-input-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.card-settings-container .double-input-container .thumb{
    color: #000;
}

.card-settings-container .double-input-container > div{
    width: 48%;
    min-width: 280px;
}

@media (max-width: 760px) {
    .card-settings-container .double-input-container{
        flex-direction: column;
    }

    .slider-container .limits-indicator{
        font-size: 16px;
    }

    .card-settings-container .double-input-container > div{
        width: 100%;
    }
}

@media (max-width: 760px) {
    .slider-title{
        font-size: 20px;
    }
}

@media (max-width: 500px) {
    .modal-container .body .card-settings-input-group{
        align-self: center;
    }
}