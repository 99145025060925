.modal-container-card-confirm{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 900px;
    min-height: 500px;
    max-width: 90%;
    background-color: #fff;
    border-radius: 15px;
    z-index: 12;
    padding: 20px 50px;
}

.modal-container-card-confirm .close-btn{
    position: absolute;
    right: 10px;
    top: 0;
    background-color: #fff;
    outline: none;
    border: none;
    font-weight: bold;
    font-size: 2rem;
}

.modal-container-card-confirm .title-container{
    display: flex;
    flex-direction: column;
    margin-top: 40px;
}
.modal-container-card-confirm .title-container .title{
    font-family: FSAlbertProBold;
    font-size: 24px;
    cursor: default;
}

.modal-container-card-confirm .header{
    display: flex;
    justify-content: space-between;
    width: 100%;
    min-height: 300px;
}

.modal-container-card-confirm .header .datails-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;
    min-width: 200px;
    min-height: 150px;
    background-color: #FEF4EA;
    border-radius: 15px;
    margin: auto 0;
    padding: 20px;
}   
.modal-container-card-confirm .header .datails-container .delivery-address{
    display: flex;
    flex-direction: column;
}

.modal-container-card-confirm .header .datails-container .confirm-btn{
    background-color: #FDB913;
    border-radius: 10px;
    min-height: 30px;
    width: 175px;
    max-width: 100%;
    padding: 0 10px;
    padding-top: 5px;
    color: #fff;
    font-size: 14px;
    font-family: FSAlbertProBold;
    cursor: pointer;
}

.modal-container-card-confirm .header .card-container{
    position: relative;
    right:0;
    max-height: 220px;
    max-width: 70%;
    margin: auto 0;
    border-radius: 15px;
    overflow: hidden;
}

.modal-container-card-confirm .header .card-logo{
    position: relative;
    right:0;
    max-width: 100%;
    max-height: 220px;
    margin: auto 0;
}
.modal-container-card-confirm .body{
    display: flex;
    justify-content: center;
    margin: 0 auto;
    width: 50%;
    min-width: 200px
}
.modal-container-card-confirm .body .continue-btn-cont{
    height: clamp(50px, 8vmin, 72px);
    width: 150px;   
    margin-top: 10px;
    margin-left: 10px;
    border-radius: 10px;
    min-width: 100px;
}

.modal-container-card-confirm .body .continue-btn-cont .continue-btn{
    width: 100%;
    height: 100%;
    background-color: #FDB913;
    border: none;
    color: #000;
    font-family: FSAlbertProBold;
    font-weight: 16px;
    border-radius: 10px;
}
.modal-container-card-confirm .body .change-btn-cont{ 
    height: clamp(50px, 8vmin, 72px);
    width: 200px;   
    margin-top: 10px;
    margin-right: 10px;
    min-width: 150px;
}

.modal-container-card-confirm .body .change-btn-cont .change-btn{
    background-color: #fff;
    border: 2px solid #FDB913;
    border-radius: 10px;
    height: 100%;
    padding: 0 10px;
    color: #FDB913;
    font-family: FSAlbertProBold;
    font-weight: 16px;
}

@media (max-width:560px) {
    .modal-container-card-confirm .header{
        flex-direction: column;
        align-items: center;
    }

    .modal-container-card-confirm .header .card-container{
        margin-top: 10px;
    }
}

@media (max-width: 484px) {
    .modal-container-card-confirm .body{
        flex-direction: column;
        align-items: center;
    }

    .modal-container-card-confirm .body .change-btn-cont{ 
        margin-right: 0px;
    }

    
    .modal-container-card-confirm .body .continue-btn-cont{ 
        margin-left: 0;
    }
}