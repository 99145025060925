.phone-verification-txt{

    width: 399px;
    height: 22px;
    left: 740px;    
    top: 201px;

    font-family: FSAlbertProBold;
    font-size: 48px;
    line-height: 22px;

    /* identical to box height, or 46% */
    letter-spacing: -1.152px;
    color: #000000;
    cursor: default;
}

.page-title-reworked{
    font-size: clamp(32px, 4vw, 40px);
    line-height: clamp(32px, 4vw, 40px);    
    font-family: 'FSAlbertPro';
}

.sent-txt{

    width: 500px;
    height: 29px;
    left: 740px;
    top: 247px;

    font-family: FSAlbertPro;
    font-size: 20px;
    line-height: 29px;

    /* identical to box height, or 145% */
    letter-spacing: -0.48px;
    color: #ACB6C5;
    cursor: default;
}

.code-container{
    width: 100%;
    height: 66px;
}

.code-container input{
    font-family: FSAlbertPro;
    font-size: 24px;
    line-height: 19px;
    /* identical to box height, or 79% */
    letter-spacing: -0.576px;
    text-align: center;
    color: #000000;
}

.code-container input::placeholder{
    font-family: FSAlbertProBold;
    font-size: 24px;

    /* identical to box height, or 79% */
    letter-spacing: -0.576px;

    color: #000000;
}

.code-completed{
    background: #FEF4EA;
}

.code-incompleted{
    height: 61px;
    width: 61px;  
    border: 1px solid #FDB913;
    border-radius: 10px;
}

.code-incompleted:focus{
    outline: 1px solid #FDB913;
}

.code-placeholder{

    height: 19px;
    left: 90.79%;
    right: 4.71%;
    top: calc(50% - 19px/2 + 7.5px);

    font-family: FSAlbertPro;
    font-size: 24px;
    line-height: 19px;

    /* identical to box height, or 79% */
    letter-spacing: -0.576px;

    color: #000000;
}

.next1{
    left: 68.74%;
}
.next2{
    left: 51.61%;
}
.next3{
    left: 34.26%;
}
.next4{
    left: 17.13%;
}
.next5{
    left: 0;
}

.resend-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.resend-txt{
    font-family: FSAlbertPro;
    font-size: 20px;
    line-height: 19px;
    /* identical to box height, or 106% */
    color: #1A1919;
}

@media (max-width: 760px) {
    .resend-txt{
        font-size:16px;
    }
}

.resend-container .icon{
    
    width: 16px;
    height: 16px;
}

.continue-btn-container-higher{
    width: 100%;    
    height: clamp(40px, 8vmin, 72px);
}

.already-container{
    display: flex;
    justify-content: center;
    width: 90%;
    bottom: 10px;
    z-index: 1;
}

@media( max-width: 992px){
    .code-incompleted{
        height: 55px;
        width: 55px;
    }
}
@media (max-width: 576px) {   
    .code-container{
        width: 95vw !important;
    }
    .code-incompleted{
        height: 50px;
        width: 50px;
    }
}
