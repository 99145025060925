.sticky-header-container{
    position: fixed;
    display: flex;
    justify-content: space-between;
    align-items: center;
    justify-self: center;
    top: 0px;
    height: 80px;
    width: 100%;
    max-width: 1400px;
    background-color: #fef4ea;
    box-shadow: -2px 2px 10px #000;
    z-index: 10;
}

.sticky-header-container .header-button{
    display: flex;
    margin-left: 125px;
    height: 40px;
    padding: 0 10px;
    border: 1px solid black;
    border-radius: 13px;
    cursor: pointer;
    background-color: #FDB913;
}

.sticky-header-container  span{
    margin: auto 0;
    font-size: 24px;
    font-family: FSAlbertPro;
    text-align: center
}

.sticky-header-container .link{
    display: flex;
    margin-left: 30px;
    padding: 0 5px;
    cursor: pointer;
    transition: 0.4s all;
}

.sticky-header-container .link:hover{
    color: #FDB913;
}

.sticky-header-container .header-name{
    margin-right: 50px;    
    cursor: pointer;
}

.sticky-header-container .header-name .name{
    display: flex;
}

.sticky-header-container .header-name .name span{
    font-family: FSAlbertProBold;
    font-size: 32px;
}

.download-container{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 50px;;
    background-image: url(../../Images/Heads/full\ head.png);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}

.download-container .title-download{
    align-self: center;
    font-size: 32px;
    margin-top: 75px;
    font-family: FSAlbertProBold;
}

.download-container .icons-container{
    display: flex;
    justify-content: space-between;
    margin: 75px auto;
    margin-top: 20px;
    width: 500px;
    max-width: calc(100% - 40px);
    padding: 0 20px;
}

.download-container .icons-container img{
    width: 40%;
    min-width: 150px;
    cursor: pointer;
}



@media (max-width: 950px) {
    .sticky-header-container .header-name{
        margin-right: 10px;
    }

    .sticky-header-container .header-button{
        margin-left: 10px;
    }
}