.add-photo{
    width: 100px;
    height: 30px;
}

.img-full{
    max-height: 400px;
    margin: 20px 0;
}

.img-preview{
    width: 200px;
    height: 200px;
    border-radius: 1000px;
}