.terms-cond-txt{

    width: 493px;
    height: 22px;
    left: 740px;
    top: 201px;

    font-family: FSAlbertPro;
    font-size: 48px;
    line-height: 22px;

    /* identical to box height, or 46% */
    letter-spacing: -1.152px;
    color: #000000;
    cursor: default;
}

.agree-txt{

    width: 89px;
    height: 29px;
    left: 740px;
    top: 247px;

    font-family: FSAlbertPro;
    font-size: 20px;
    line-height: 29px;

    /* identical to box height, or 145% */
    letter-spacing: -0.48px;
    color: #ACB6C5;
    cursor: default;
}

.terms-cond-container{
    width: 100%;
    height: 366px;
    left: 740px;
    top: 348px;
}

.terms-cond-container .txt{
    margin-right: 57px;
    font-size: 16px;
}

@media (max-width: 992px) {
    .terms-cond-container .txt{
        margin-right: 20px;
    }
}

.fade{
    width: 569px;
    height: 80px;
    left: 740px;
    top: 634px;
    background: linear-gradient(180deg, rgba(238, 238, 238, 0.0001) 0%, transparent 100%);
}

.fade-none{
    display: none;
}

.track-vertical2{
    top: 2px;
    bottom: 2px;
    right: 10px;
    border-radius: 10px;
    background: #FEF4EA;
}

.track-vertical2 .thumb-vertical2{
    position: relative;
    display: block;
    width: 100%;
    cursor: pointer;
    border-radius: inherit;
    background-color: rgba(0, 0, 0, 0.8);
}

.checkbox{
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-top: 32px;
}

.checkbox .message{
    font-family: FSAlbertPro;
    font-size: 16px;
    line-height: 19px;
    max-width: 100%;
    /* identical to box height, or 158% */
    color: #323232;
    align-self: center;
    margin-left: 16px;
}


.checkbox-container{
    position: relative;
    margin-bottom: 1px;
}

.checkbox input[type="checkbox"]:checked {
    color: white;
}
  
.checkbox input[type="checkbox"] {
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: 0;
    background: #fff;
    height: 22px !important;
    width: 22px !important;
    min-width: 22px;
    margin-right: 10px;
    border: 1px solid #000;
    color: white;
    border-radius: 5px;
}
  
.checkbox input[type="checkbox"]:after {
    content: ' ';
    position: relative;
    top: 4px;
    left: 7px;
    width: 25%;
    height: 50%;
    border: 2px solid #fff;
    border-width: 0 2.5px 2.5px 0;
    transform: rotate(50deg);
    display: none;
    background: #FDB913;
}
  
.checkbox input[type="checkbox"]:checked {
    background: #FDB913;
}

    
.checkbox input[type="checkbox"]:checked:after {
    display: block;
}
