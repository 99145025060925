.confirmation-container{
    width: 100%;
    height: auto;
}

.confirmation-container .checkmark2{
    position: relative;
    width: 64px;
    height: 64px;
}

.checkmark2 .line2{
    position: absolute;
    width: 7px;
    height: 18.81px;
    top:28px;
    left: 22px;
    border-radius: 5px;
    transform: rotate(-45deg);
    background-color: #fff;
}

.checkmark2 .line1{
    position: absolute;
    width: 7px;
    height: 30.81px;
    top:16px;
    left: 33px;
    border-radius: 5px;
    transform: rotate(30deg);
    background-color: #fff;
}

.checkmark2 .oval{
    width: 64px;
    height: 64px;
    border-radius: 1000px;
    background: #3EA6E8;
    z-index: -1;
}

.confirmation-container .congrats{
    font-family: FSAlbertProBold;
    font-size: 24px;
    line-height: 22px;
    /* identical to box height, or 92% */
    text-align: center;
    letter-spacing: -0.576px;
    color: #000000;
}

.confirmation-container .message{
    font-family: FSAlbertPro;
    font-size: 20px;
    line-height: 19px;
    /* identical to box height, or 119% */
    text-align: center;
    letter-spacing: -0.384px;
    color: #1A1919;
}

@media (max-width: 760px) {
    .confirmation-container .congrats{
        font-size: 20px;
    }

    .confirmation-container .message{
        font-size: 16px;
    }
}

.confirmation-container .text-container .message .name{
    font-family: FSAlbertProBold;
}

.right .continue-btn{
    margin-bottom: 20px;
    height: clamp(40px, 7vmin, 60px);
}