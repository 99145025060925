.landing-page-container{
    display: flex;
    flex-direction: column;
    width: 1400px;
    max-width: 100%;
    margin: 0 auto;
    min-height: 100%;
}

.landing-page-container .top-photoes{
    display: flex;
    width: 100%;
    height: 950px;
    overflow: hidden;
    background-color: #FEF4EA;
    background-size: cover;
    background-repeat: no-repeat;
    overflow: hidden;
}

.landing-page-container .top-photoes .landing-left{
    width: 60%;
    display: flex;
    height: 100%;
    background-image: url('../Images/Landing/fans.png');
    background-position: center center;
    background-size: cover;
    transform: translateX(-70%);
    animation-fill-mode: forwards; 
    -moz-animation-fill-mode: forwards; 
}

.landing-page-container .top-photoes .landing-left .info{
    display: flex;
    flex-direction: column;
    font-family: FSAlbertPro;
    width: 70%;
    min-height: 380px;
    margin: auto;
    border-radius: 1px solid #fff;
}

.landing-page-container .top-photoes .landing-left .info .download-icons{
    display: flex;
    width: 100%;
    margin-top: 20px;
}

.landing-page-container .top-photoes .landing-left .info .text1{
    font-family: FSAlbertPro;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: -0.576px;
    color: #FDB913;
    cursor: default;
}

.landing-page-container .top-photoes .landing-left .info .text2{
    font-family: FSAlbertProBold;
    margin-top: 48px;
    font-size: 72px;
    line-height: 75px;
    color: #fff;
    letter-spacing: -1.728px;
    cursor: default;
}

.landing-page-container .top-photoes .landing-left .info .text3{
    margin-top: 14px;
    font-family: FSAlbertPro;
    font-size: 32px;
    line-height: 37px;
    letter-spacing: -0.768px;
    color: #FFFFFF;
    cursor: default;
}

.landing-page-container .top-photoes .landing-left .info button{
    margin-top: 48px;
    width: 165px;
    height: 51px;
    border: none;
    border-radius: 13px;
    background-color: #FDB913;

    font-family: FSAlbertPro;
    font-size: 18px;
    line-height: 19px;

    /* identical to box height, or 106% */

    color: #000000;
}

.landing-page-container .top-photoes .landing-right{
    width: 40%;
    height: 100%;
    transform: translateX(70%);
    animation-fill-mode: forwards; 
    -moz-animation-fill-mode: forwards; 
}

.landing-page-container .top-photoes .landing-right img{
    position: relative;
    left: -250px;
    top: -150px;
    height: 1500px;
}

.landing-page-container .section1{
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.landing-page-container .section1 .title{
    font-family: FSAlbertPro;
    padding: 0 15px;
    font-size: 32px;
    line-height: 37px;
    text-align: center;
    letter-spacing: -0.768px;
    color: #000000;
    margin: 0 auto;
    margin-top: 100px;
    cursor: default;
}

.landing-page-container .section1 .icons{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-around;
    margin-top: 85px;
}

.landing-page-container .section1 .icons .icon{
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    width: 360px;
    padding: 0 15px;
    height: calc(81px + 35px + 214px);
}

.landing-page-container .section1 .icons .icon img{
    width: 214px;
    height: 214px;
    margin: 0 auto;
}


.landing-page-container .section1 .icons .icon .text{
    width: 100%;
    font-family: FSAlbertPro;
    font-size: 21px;
    line-height: 27px;
    text-align: center;
    letter-spacing: -0.504px;
    color: #323232;
    cursor: default;
}

.landing-page-container .section-split{
    display: flex;
    width: 100%;
    min-height: 881px;
    margin-top: 147px;
    overflow: hidden;
}

.landing-page-container .section-split .landing-left{
    position: relative;
    width: 50%;
    height: 881px;
}

.landing-page-container .section-split .landing-left .background{
    position: relative;
    display: flex;
    width: 90%;
    max-width: 600px;
    height: 100%;
    background-color: #FEF4EA;
    border-radius: 0px 67px 67px 0px;
}

.landing-page-container .section-split .landing-left .background .phone-card .phone{
    position: absolute;
    top: 80px;
    right: 40px;
    opacity: 0;
    animation-fill-mode: forwards; 
    -moz-animation-fill-mode: forwards; 
}

@keyframes activeLeftDelayied {
    from {
        transform: translateX(-100%);
        opacity: 0;
    }
    to{
        transform: translateX(0);
        opacity: 1;
    }
} 

@keyframes activeLeftDelayiedSmall {
    from {
        transform: translateX(-100%);
        opacity: 0;
    }
    to{
        transform: translateX(-20%);
        opacity: 1;
    }
} 


.active-left-delayied{
    animation: activeLeftDelayied 2.5s 0.3s;
}

.active-left-delayied-small{
    animation: activeLeftDelayiedSmall 2.5s 0.3s;
}

.landing-page-container .section-split .landing-left .background .phone-card .card{
    position: absolute;
    top: 370px;
    right: -95px;
    opacity: 0;
    animation-fill-mode: forwards; 
    -moz-animation-fill-mode: forwards; 
}

.landing-page-container .section-split .landing-left .background .repx{
    position: absolute;
    bottom: 50px;
    width: 350px;
    right: 60px;
    text-align:center;
    font-family: FSAlbertProBold;
    font-size: 14px;
    line-height: 26px;
    text-align: center;
    letter-spacing: -0.336px;
    cursor: default;
    color: #1A1919;
}

.landing-page-container .section-split .landing-left img{
    position: absolute;
    top: 29px;
    right: -100px;
}

.landing-page-container .section-split .landing-right{
    display: flex;
    flex-direction: column;

    width: 50%;
    transform: translateX(70%);
    opacity: 0;
    animation-fill-mode: forwards; 
    -moz-animation-fill-mode: forwards; 
}

.landing-page-container .section-split .landing-right .text-container{
    width: 80%;
    min-height: 360px;
    margin-left: 50px;
}

.landing-page-container .section-split .landing-right .text-container .title{
    font-family: FSAlbertProBold;
    font-size: 38px;
    line-height: 38px;
    letter-spacing: -1.152px;
    color: #1A1919;
}

.landing-page-container .section-split .landing-right .text-container .text1{
    font-family: FSAlbertPro;
    font-size: 18px;
    line-height: 31px;
    letter-spacing: -0.432px;
    color: #9F9F9F;
}

@media (max-width: 1188px) {
    .landing-page-container .section-split .landing-right .text-container .title{
        font-size: 32px;
        line-height: 32px;
    }
    .landing-page-container .section-split .landing-right .text-container .text1{
        font-size: 14px;
    }
}

.landing-page-container .section-split .landing-right .icons{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 350px;
    margin-top: 78px;
    margin-left: 60px;
    min-height: 200px;
}

.landing-page-container .section-split .landing-right .icons .icon{
    display: flex;
    flex-direction: column;
    width: 160px;
    height: 113px;
}

.landing-page-container .section-split .landing-right .icons .icon img{
    width: 63px;
    height: 63px;
    margin: 0 auto;
}

.landing-page-container .section-split .landing-right .icons .icon .text{
    font-family: FSAlbertPro;
    margin-top: 8px;
    font-size: 14px;
    line-height: 19px;
    color: #1A1919;
    text-align: center;
    cursor: default;
}

.landing-page-container .section-split2{
    display: flex;
    margin-top: 130px;
    width: 100%;
    height: 624px;
    overflow: hidden;
}

.landing-page-container .section-split2 .landing-left{
    display: flex;
    width: 50%;
    height: 100%;
}

.landing-page-container .section-split2 .landing-left .info-container{
    display: flex;
    flex-direction: column;
    margin: auto;
    width: 80%;
    min-height: 138px;
}

.landing-page-container .section-split2 .landing-left .info-container .text1{
    font-family: FSAlbertProBoldExtra;
    font-size: 48px;
    line-height: 75px;
    letter-spacing: -1.152px;
    color: #1A1919;
    cursor: default;
}

.landing-page-container .section-split2 .landing-left .info-container .text2{
    font-family: FSAlbertPro;
    font-size: 18px;
    line-height: 31px;
    letter-spacing: -0.432px;
    color: #1A1919;
    margin-top: 30px;
    cursor: default;
}

.landing-page-container .section-split2 .landing-right{
    position: relative;
    display: flex;
    height: 100%;
    width: 50%;    
}

.landing-page-container .section-split2 .landing-right .background{
    position: absolute;
    right: 0;
    width: 80%;
    max-width: 600px;
    height: 100%;
    background-color: #FEF4EA;
    border-radius: 67px 0px 0px 67px;
}

.active-right-delay1{
    animation: active-right 2.5s 0.5s;
}

.active-right-delay2{
    animation: active-right 2.5s 1s;
}

.landing-page-container .section-split2 .landing-right .background .cards{
    position: relative;
    margin-top: 100px;
    width: 467px;
    height: 367px;
}

.landing-page-container .section-split2 .landing-right .background .cards .card1{
    position: absolute;
    top: 0;
    left: -100px;
    opacity: 0;
    animation-fill-mode: forwards; 
    -moz-animation-fill-mode: forwards; 
}

.landing-page-container .section-split2 .landing-right .background .cards .card2{
    position: absolute;
    top: 80px;
    left: -20px;
    opacity: 0;
    animation-fill-mode: forwards; 
    -moz-animation-fill-mode: forwards; 
}

.landing-page-container .section-split2 .landing-right .background .cards .card3{
    position: absolute;
    top: 160px;
    left: 60px;
    opacity: 0;
    animation-fill-mode: forwards; 
    -moz-animation-fill-mode: forwards; 
}

.landing-page-container .section-split2 .landing-right .background .text{
    position: absolute;
    width: 360px;
    right: 30%;
    bottom: 31px;
    text-align: center;
    font-family: FSAlbertProBold;
    font-size: 14px;
    line-height: 26px;
    letter-spacing: -0.336px;
    color: #1A1919;
}

@media (max-width: 1250px) {
    .landing-page-container .section-split2 .landing-right .background .text{
        right: 12%;
    }
}

/* .landing-page-container .section-split2 .landing-right img{
    position: relative;
    margin-top: 120px;
    left: -100px;
} */

.landing-page-container .section-split3{
    display: flex;
    width: 100%;
    min-height: 682px;
    margin-top: 130px;
    overflow: hidden;
}

.landing-page-container .section-split3 .landing-left{
    display: flex;
    flex-direction: column;
    width: 50%;
    height: 100%;
    transform: translateX(-70%);
    opacity: 0;
    animation-fill-mode: forwards; 
    -moz-animation-fill-mode: forwards; 
}

.landing-page-container .section-split3 .landing-left .info-container{
    width: 80%;
    align-self: flex-end;
}

.landing-page-container .section-split3 .landing-left .info-container .title{
    font-family: FSAlbertProBoldExtra;
    font-size: 42px;
    line-height: 55px;
    letter-spacing: -1.152px;
    color: #1A1919;
    cursor: default;
}

.landing-page-container .section-split3 .landing-left .info-container .message{
    font-family: FSAlbertPro;
    font-size: 18px;
    line-height: 31px;
    /* or 172% */
    letter-spacing: -0.432px;
    color: #9F9F9F;
    margin-top: 32px;
    cursor: default;
}

.landing-page-container .section-split3 .landing-left img{
    margin-top: 64px;
}

.landing-page-container .section-split3 .landing-right{
    display: flex;
    width: 50%;
    height: 100%;
    opacity: 0;
    transform: translateX(70%);
    animation-fill-mode: forwards; 
    -moz-animation-fill-mode: forwards; 
}

.active-right{
    animation: active-right  2s;
}

.active-left{
    animation: active-left  2s;
}

@keyframes active-right {
    from{
        opacity: 0;
        transform: translateX(70%);
    }

    to{
        opacity: 1;
        transform: translateX(0%);
    }
}

@keyframes active-left {
    from{
        opacity: 0;
        transform: translateX(-70%);
    }

    to{
        opacity: 1;
        transform: translateX(0%);
    }
}


.landing-page-container .section-split3 .landing-right .inputs-container{
    display: flex;
    flex-direction: column;
    margin: auto;
    width: 70%;
}

.landing-page-container .section-split3 .landing-right .inputs-container .input{
    width: 100%;
}

.landing-page-container .section-split3 .landing-right .inputs-container .input .title{
    font-family: FSAlbertProBold;
    font-size: 18px;
    line-height: 19px;
    color: #1A1919;
    cursor: default;
    margin-bottom: 16px;
}

.landing-page-container .section-split3 .landing-right .inputs-container .input input{
    height: 59px;
    width: 100%;
    border: none;
    background-color: #FEF4EA;
    border-radius: 13px;
    padding-left: 24px;
}

.landing-page-container .section-split3 .landing-right .inputs-container .input input:focus{
    outline: 1px solid #FDB913;
}

.landing-page-container .section-split3 .landing-right .inputs-container .input input::placeholder{
    color: #000;
    opacity: 1;
    font-family: FSAlbertPro;
    font-size: 18px;
    line-height: 19px;
}

.landing-page-container .section-split3 .landing-right .inputs-container .agree-container{
    display: flex;
    align-items:  center;
    margin-top: 32px;
}

.landing-page-container .section-split3 .landing-right .inputs-container .agree-container .agree-text{
    margin-left: 10px;
    font-family: FSAlbertProBold;
    font-size: 16px;
    line-height: 19px;
    color: #323232;
    cursor: default;
}

.landing-page-container .section-split3 .landing-right .inputs-container .submit-btn{
    width: 200px;
    height: 63px;
    margin-top: 48px;
}

.landing-page-container .footer{
    display: flex;
    height: 300px;
    width: 100%;
    background: #EDF3FC;
    margin-top: 50px;
}

.landing-page-container .footer .text-container{
    width: 60%;
    margin: auto;
    min-width: 350px;
    min-height: 150px;
}

.landing-page-container .footer .text-container .text1{
    font-family: FSAlbertPro;
    width: 100%;
    font-size: 18px;
    line-height: 33px;
    text-align: center;
    color: #1A1919;
}

.landing-page-container .footer .text-container .text2{
    margin-top: 64px;
    text-align: center;
}

.landing-page-container .error{
    color: red;
    margin-top: 10px;
    font-family: FSAlbertProBold;
    font-size: 1.3rem;
}

.landing-page-container .redirect-btn{
    margin: 0 auto;
    display: flex;
    margin-top: 40px;
    height: 50px;
    background-color: #FDB913;
    border-radius: 15px;
    padding: 0 10px;
    cursor: pointer;
}

.landing-page-container .redirect-btn span{
    margin: auto;
    font-size: 1.3rem;
    font-family: FSAlbertProBold;
}

@media (max-width: 998px) {
    .landing-page-container .top-photoes .landing-left{
        width: 100%;
    }

    .landing-page-container .top-photoes .landing-left .info{
        align-items: center;
        text-align: center;
    }
    
    .landing-page-container .top-photoes .landing-left .info .download-icons{
        justify-content: space-around;
    }

    .landing-page-container .top-photoes .landing-right{
        display: none;
    }

    .landing-page-container .section-split{
        flex-direction: column;
    }

    .landing-page-container .section-split .landing-left{
        width: 100%;
    }
    
    .landing-page-container .section-split .landing-left .background{
        width: 100%;
        max-width: none;
        border-radius: 0px;
    }
    
    .landing-page-container .section-split .landing-left .background .phone{
        left: 20%;
        transform: translateX(calc(-50% - 70px));
    }

    .landing-page-container .section-split .landing-left .background .card{
        left: 40%;
        transform: translateX(calc(-50% + 70px));
    }

    .landing-page-container .section-split .landing-left .background .repx{
        left: 50%;
        transform: translateX(-50%);
    }

    .landing-page-container .section-split .landing-right{
        width: 100%;        
    }

    .landing-page-container .section-split .landing-right .text-container{
        width: 100%;
        margin-left: 0px;
        padding: 0 20px;
        text-align: center;
    }

    .landing-page-container .section-split .landing-right .text-container .title{
        margin-top: 32px;
    }

    .landing-page-container .section-split .landing-right .icons{
        width: 100%;
        margin-left: 0px;
        padding: 0 20px;
        justify-content: space-around;
    }

    .landing-page-container .section-split2{
        flex-direction: column-reverse;
    }

    .landing-page-container .section-split2 .landing-right{
        width: 100%;
    }

    .landing-page-container .section-split2 .landing-right .background{
        position: relative;
        width: 100%;
        max-width: none;
        border-radius: 0px;
    }

    .landing-page-container .section-split2 .landing-right .background .cards{
        margin-top: 0;
        margin-bottom: 100px;
        transform: translateX(calc(-50% + 80px));
    }

    .landing-page-container .section-split2 .landing-right .background > *{
        left: 50%;
        transform: translateX(-50%);
    }

    .landing-page-container .section-split2 .landing-right .background img{
        padding: 0 15px;
        margin-top: 20px;
        margin-bottom: 100px;
        max-width: 100%;
    }

    .landing-page-container .section-split2 .landing-left {
        width: 100%;
        text-align: center;
    } 

    .landing-page-container .section-split3{
        flex-direction: column;
    }

    .landing-page-container .section-split3 .landing-left{
        width: 100%;
        text-align: center;
    }

    .landing-page-container .section-split3 .landing-left .info-container{
        width: 100%;
        padding: 0 15px;
    }

    .landing-page-container .section-split3 .landing-right{
        width: 100%;
        margin-top: 15px;
    }

    .landing-page-container .footer{
        margin-top: 50px;
    }
}

@media (max-width: 610px) {
    .landing-page-container .top-photoes .landing-left .info .text2{
        font-size: 50px;
    }

    .landing-page-container .top-photoes .landing-left .info .text3{
        font-size: 25px;
    }

    .landing-page-container .section-split .landing-left .background .phone{
        top: 200px !important;
        left: 10%;
        width: 250px;
    }

    .landing-page-container .section-split .landing-left .background .card{
        width: 220px; 
        top: 420px !important;
        transform: translateX(calc(-50% - 100px));
    }

    .landing-page-container .section-split2 .landing-right .background .cards{
        margin-bottom: 30px;
        margin-left: 80px;
    }

    .landing-page-container .section-split2 .landing-right .background .cards img{
        width: 220px;
    }
}