.transactions-container{
    position: relative;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    border-radius: 15px;
    min-height: 480px;
    width: calc(100%);
    padding: 30px !important;
}

@media (max-width: 1370px) {
    .transactions-container{
        width: calc(100% - 45px);
    }
}

.transactions-container .transaction-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.transactions-container .transaction-header .transaction-title{
    font-size: 24px;
    font-family: FSAlbertProBold;
}

.transactions-container .transaction-header .search-bar{
    display: flex;
    background-color: #FEF4EA;
    height: 60px;
    width: 300px;
    border-radius: 8px;
    padding: 0 10px;
    margin-right: 20px;
    align-items: center;
}

.transactions-container .transaction-header .search-bar input{
    background-color: transparent;
    border: none;
    outline: none;
    width: 280px;
    margin-left: 10px;
    font-size: 14px;
    font-family: FSAlbertPro;
}

.transactions-container .transaction-header .search-bar input::placeholder{
    font-size: 18px;
    font-family: FSAlbertPro;
}

.transactions-container .transaction-header .analytics-button{
    cursor: pointer;
}

.transactions-container .transaction-header .analytics-button img{
    height: 60px;
}

.transactions-container .transaction-body{
    width: 48%;
    min-width: 290px;
    min-height: 100px;
}

.transactions-container .transaction-body .date-selectors-container{
    display: flex;
    justify-content: space-between;
    height: 40px;
    margin-top: 10px;
    width: 50%;
    min-width: 270px;
}

.transactions-container .transaction-body .date-selectors-container .year-container{
    width: 90px;
    height: 100%;
}

.transactions-container .transaction-body .date-selectors-container .special-container{
    width: 120px;
    height: 100%;
}

.transactions-container .transaction-body .date-selectors-container .month-container{
    width: 70px;
    height: 100%;
}

.transactions-container .transaction-body .transaction-group{
    width: 100%;
    min-height: 100px;
    margin-top: 20px;
}

.transaction-modal{
    position: fixed;
    width: 60%;
    min-width: 350px;
    min-height: 300px;
    top: 100%;
    left: 50%;
    transform: translate(-50%, calc(-100vh + 50%));
    border-radius: 15px;
    background-color: #fff;
    z-index: 12;
    padding: min(50px, 5%);
}

.transaction-modal .info-container{
    position: relative;
}

.transaction-modal .info-container .close-btn{
    position: absolute;
    right: -10px;
    top: -20px;
    background-color: transparent;
    outline: none;
    border: none;
    font-weight: bold;
    font-size: 1.4rem;
}

.transaction-modal .info-container .transaction-title{
    font-family: FSAlbertProBold;
    font-size: 22px;
}

.transaction-modal .info-container .preview-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 300px;
    max-width: 100%;
    margin-top: 20px;
    padding-right: 10px;
}

.transaction-modal .info-container .preview-container .profile-pic{
    position: relative;
    width: 36px;
    height: 36px;
}
.transaction-modal .info-container .preview-container .profile-pic img{
    border-radius: 1000px;
}
.transaction-modal .info-container .preview-container .profile-pic .transaction-type{
    position: absolute;
    bottom: 0px;
    left: 20px;
}

.transaction-modal .info-container .preview-container .details-container{
    margin-left: 10px;
    width: 150px;
}

.transaction-modal .info-container .preview-container .details-container .name{
    font-family: FSAlbertPro;
    font-weight: 14px;
    font-size: 0.9rem;
}

.transaction-modal .info-container .preview-container .details-container .date{
    color: grey;
    font-family: FSAlbertPro;
    font-size: 0.8rem;
}

.transaction-modal .info-container .preview-container .sum-container span{
    font-family: FSAlbertPro;
    font-size: 0.8rem;
}

.transaction-modal .info-container .address{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    border-radius: 10px;
    width: 100%;
    height: 50px;
    margin: 20px 0;
    background-color: #FEF4EA;
}

.transaction-modal .info-container .help-info{
    color: grey;
    font-family: FSAlbertPro;
    font-size: 0.8rem;
}

.transaction-modal .info-container .actual-info{
    font-family: FSAlbertPro;
    font-size: 0.9rem;
    margin-right: 20%;
    text-align:end
}

.transaction-modal .info-container .other-details{
    width: 100%;
    padding: 10px 15px;
    border-radius: 10px;
    background-color: #FEF4EA;
}

.transaction-modal .info-container .other-details .data-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 30px;
}

@media (max-width: 914px) {
    .transactions-container{
        width: 100%;
    }
}

@media (max-width: 750px){
    .transaction-modal .info-container .actual-info{
        margin: 0px;
    }
}



@media (max-width: 590px){
    .transactions-container .transaction-header{
        flex-direction: column;
    }
    .transactions-container .transaction-header .analytics-button img{
        height: 45px;
    }

    .transactions-container .transaction-header .search-bar{
        height: 45px;
        width: 200px;
    }
}