.optional-adreess-container{
    width: 100%;
    height: clamp(75px, 14vmin, 96px);
    min-width: 300px;
}

.main-adress-container{
    width: 100%;
    height: clamp(75px, 14vmin, 96px);
    min-width: 300px
}

.small-container{
    width: 100%;
    height: clamp(75px, 14vmin, 96px);
    display: flex;
    justify-content: space-between;
    min-width: 300px;
}