.support-container{
    width: calc(100%);
}

@media (max-width: 1370px) {
    .support-container{
        width: calc(100% - 45px);
    }
}

@media (max-width: 914px) {
    .support-container{
        width: 100%;
    }
}

.support-container .inputs-container{
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 30px;
    width: 100%;
    margin-top: 20px;
    border-radius: 15px;
    background: #fff; 
    min-height: 200px;
}