.card-list-container{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    min-height: 500px;
    background-color: #fff;
    border-radius: 15px;
    padding: 30px;
}

.card-list-container .add-card{
    display: flex;
    align-self: center;
    align-items: center;
    width: 220px;
    height: 50px;
    padding: 0 10px;
    margin-bottom: 30px;
    border-radius: 13px;
    cursor: pointer;
    border: 1px solid #000;
}

.card-list-container .add-card .title{
    font-size: 20px;
    font-family: FSAlbertProBold;
    margin-left: 30px;
}

.card-list-page{
    display: flex;
    justify-content: space-between;
}

.card-list-page .virtual-cards,
.card-list-page .real-cards{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 48%;
    min-width: 320px;
}

.card-list-page .title{
    font-size: 24px;
    width: 480px;
    border-bottom: 1px solid black;
    margin-bottom: 10px;
    font-family: FSAlbertPro;
}

.card-list-page .card-list-item{
    display: flex;
    align-items:center;
    margin-bottom: 10px;
    cursor: pointer;
}

.card-list-page img{
    width: 320px;
    border-radius: 15px;
}

.card-list-page .id{
    margin-left: 10px;
    font-size: 20px;
    min-width: 130px;
    font-family: FSAlbertPro;
}


@media (max-width: 1370px) {
    .card-list-container{
        width: calc(100% - 45px);
    }
}  

@media (max-width: 1200px) {
    .card-list-page{
        flex-direction: column;
        align-items: center;
    }

    .card-list-page .virtual-cards,
    .card-list-page .real-cards{
        width: min(100%, 480px);
    }

    .card-list-page .real-cards{
        margin-top: 30px;
    }
}

@media (max-width: 914px) {
    .card-list-container{
        width: 100%;
    }
}

@media (max-width: 545px) {
    .card-list-page .card-list-item{
        flex-direction: column-reverse;
    }

    .card-list-page .title{
        width: 280px;
    }

    .card-list-page img{
        width: 280px;
    }
}