.carousel{
  position: relative;
  overflow: hidden;
  display: flex;
  white-space: nowrap;
  word-wrap: unset;
  flex-wrap: nowrap;
  width: 100%;
  height: 100%;
}

.inner{
  position: absolute;
  display: flex;
  flex-direction: row;
  word-wrap: unset;
  height: 100%;
  width: 300%;
  transition: transform 0.3s;
}

.slideshow-text{
  text-align: center;
  position: absolute;
  width: 80%;
  min-height: 164px;
  bottom: 50px;
  z-index: 5;
}

.image{
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position:center center;
}

.slideshow-text .text-low{
  position: absolute;
  height: 29px;
  left: 0%;
  right: 0%;
  top: calc(50% - 29px/2 - 14.5px);

  font-family: FSAlbertPro;
  font-size: 16px;
  line-height: 29px;

  /* identical to box height, or 181% */
  letter-spacing: -0.384px;

  color: #AEAEAE;
}

.slideshow-text .text-up{
  position: absolute;
height: 29px;
left: 4.81%;
right: 4.56%;
top: calc(50% - 29px/2 - 67.5px);

font-family: FSAlbertPro;
font-size: 21px;
line-height: 25px;
letter-spacing: 4.2175px;
text-transform: uppercase;
color: #FFFFFF;
}

.carousel-item{
  display: inline-flex;

  justify-content: center;
  height: 100%;
  background-color: transparent;
  color: #fff;
  z-index: -10;
}

.carousel-item img{
  z-index: -2;
}

.bulets{
  display: flex;
  justify-content: center;
  width: 100%;
  z-index: 10;
  margin-top: 40%;
}

.bulets > button{
  margin: 5px;
  height: 10px;
  width: 10px;
  margin-top: 1.5px;
  cursor: pointer;
  border: 0px;
  border-radius: 1000px;
  background-color: #9F9F9F;
}

.bulets > button.active{
  background-color: #fff;
  height: 13px;
  width: 13px;
  margin-top: 0px;
}

@media (max-width: 992px) {
  .slideshow-container-main{
      display: none;
      visibility: collapse;
      height: 0;
  }
}
