.upText{
    font-family: FSAlbertPro;
    font-size: 24px;
    line-height: 19px;

    /* identical to box height, or 106% */
    cursor: default;
    color: #1A1919;
}


.dd-contaier{
    width: 100%;    
    margin: 0px auto;
    margin-bottom:  5px;
    display: flex;
    flex-direction: column;
    /* height: clamp(65px, 14vmin, 96px); */
    overflow: hidden;
}


.dd-header{
    width: 100%;
    height: clamp(40px, 7vmin, 60px);
    /* height: clamp(65px, 14vmin, 96px); */
    cursor: pointer;
    display: flex;
    border-radius: 13px;
    border: 1px solid #fdb913;
    box-sizing: border-box;
    background-color: #FEF4EA;
}

.dd-header-outline{
    border: 1px solid #000;
}

.dd-header .dd-header-unselected{
    width: 100%;
    font-family: FSAlbertPro;
    font-size: 20px;
    line-height: 19px;
    /* identical to box height, or 106% */
    color: #c9cacc;
}

.dd-header .dd-header-selected{
    color: #000;
}

@keyframes open-arrow{
    to {transform: rotate(180deg);}
}

@keyframes close-arrow{
    from {transform:rotate(180deg);}
    to {transform: rotate(0deg);}
}

.dd-arrow{
    width: 20px;
    height: 20px;
    background-image: url('../../Images/Icons/triangle.png');
    background-size: 20px;    
    animation-fill-mode: forwards; 
    -moz-animation-fill-mode: forwards; 
}

.dd-arrow-openned{
    animation-name: open-arrow;
    animation-duration: 0.5s;
}

.dd-arrow-closed{
    animation-name: close-arrow;
    animation-duration: 0.5s;
}

@keyframes growDown {
    0% {
      transform: translateY(-250px);
    }
    
    100% {
      transform: translateY(0px);
    }
}

.dd-contaier .hide{
    height: 260px;
    width: 100%;
    border-radius: 13px;
    background-color: transparent;
    border: 0px;
    margin-top: 5px;
    overflow: hidden;
    z-index: 10;
}

.dd-list-container{
    position: relative;
    width: 99.9%;
    height: 250px;
    margin-top: 5px;
    box-sizing: border-box;
    border-radius: 13px;
    cursor: pointer;
    border: 1px solid black;
    overflow: hidden;
    background-color: #fff;
    animation: growDown 0.3s forwards;
    z-index: 10;
}

.dd-wrapper:hover{
    background-color:rgb(255, 197, 73);
}

.dd-list-item-container{
    height: 40px;
    border-bottom: 1px solid #FEF4EA;
}

.dd-list-item-container:hover{
    background: #FEF4EA;
}

.dd-list-item{
    list-style: none;
    margin: 1px solid black;
    padding: 10px 15px;

    font-family: FSAlbertPro;
    font-size: 18px;
    line-height: 19px;

    /* identical to box height, or 106% */
    color: #323232;
}

.dropdown2-container{
    height: 100%;
    width: 100%;
    background-color: #FEF4EA;
    border-radius: 13px;
    border: 1px solid
}

.dropdown2-container .dropdown2-header{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 10px;
    cursor: pointer;
}

.dropdown2-container .dropdown2-header img{
    animation-fill-mode: forwards; 
    -moz-animation-fill-mode: forwards; 
    transition: 0.5s;
}

.dropdown2-container .dropdown2-header .dropdown2-arrow-opened{
    transform: rotate(180deg);
}

.dropdown2-container .dropdown2-header .dropdown2-arrow-closed{
    transform: rotate(0deg);
}

.dropdown2-container .dropdown2-header span{
    font-family: FSAlbertPro;
    font-size: 20px;
    line-height: 19px;
}

.dropdown2-container .dropdown2-list{
    position: relative;
    background-color: #fff;
    border: 1px solid black;
    border-radius: 10px;
    margin-top: 10px;
    overflow: hidden;
    height: 150px;
    z-index: 11;
}

.dropdown2-container .dropdown2-list .dropdown2-item{
    padding: 3px 0;
    padding-left: 10px;
    border-bottom: 1px solid #FEF4EA;
    cursor: pointer;
}

.dropdown2-container .dropdown2-list .dropdown2-item:hover{
    background-color: #FEF4EA;
}

.dropdown2-container .dropdown2-list .dropdown2-item span{
    font-family: FSAlbertPro;
    font-size: 14px;
}

.dd-date-container{
    width: 100%;
    height: 100%;
    border-radius: 10px;
    border: none;
    background-color: #FEF4EA;
    cursor: pointer;
}

.dd-date-container .dd-date-header{
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 10px;
}
.dd-date-container .dd-date-header .time-selected{
    padding-left: 10px;
}

.dd-date-container .dd-date-header .time-selected span{
    text-align: center;
    font-family: FSAlbertPro;
    font-size: 20px;
    line-height: 19px;
}

.dd-date-container .dd-date-list{
    position: relative;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    z-index: 2;
    padding: 10px;
    border-radius: 10px;
    background-color: #FEF4EA;
}
.dd-date-container .dd-date-list .dd-date-item{
    display: flex;
    height: 40px;
    padding: 0 10px;
    border-radius: 10px;
}

.dd-date-container .dd-date-list .dd-date-item:hover{
    background-color: black;
}

.dd-date-container .dd-date-list .dd-date-item:hover span{
    color: #fff;
}

.dd-date-container .dd-date-list .dd-date-item span{ 
    margin: auto 0;
    font-family: FSAlbertPro;
    font-size: 1.1rem;
    line-height: 19px;
}


.dd-date-container .date-picker-body-container{
    position: relative;
    z-index: 3;
}

.dropdown-simple-arrow{
    position: relative;
    height: 100%;
    width: 100%;
    border-radius: 10px;
}

.dropdown-simple-arrow .header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    padding: 0 10px;
    cursor: pointer;
    background-color: #FEF4EA;
    font-family: FSAlbertPro;
}

.dropdown-simple-arrow .dd-list{
    position: relative;
    top: 10px;
    min-height: 100px;
    width: 100%;
    border-radius: 10px;
    background-color: #FEF4EA;
    overflow: hidden;
    z-index: 2;
}

.dropdown-simple-arrow .dd-list .dd-item{
    display: flex;
    align-items: center;
    height: 40px;
    width: 100%;
    padding: 0 10px;
    cursor: pointer;
}

.dropdown-simple-arrow .dd-list .dd-item:hover{
    background-color: #fdb913;
}

@media (max-width: 760px) {
    .upText{
        font-size: 20px;
    }

    .dd-header .dd-header-unselected{
        font-size: 16px;
    }

    .dd-header .dd-header-selected{
        font-size: 16px;
    }
}