.modal-container-card-done{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 900px;
    min-height: 200px;
    max-width: 90%;
    border-radius: 15px;
    z-index: 12;
    padding: 20px 40px;
    background-color: #fff;
    background-image: url('../Images/Heads/full\ head.png');   
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}

.modal-container-card-done .close-btn{
    position: absolute;
    right: 10px;
    top: 0;
    background-color: #fff;
    outline: none;
    border: none;
    font-weight: bold;
    font-size: 2rem;
}

.modal-container-card-done .header{
    display: flex;
    justify-content: space-between;
    width: 100%;
    min-height: 300px;
    padding: 50px 0px;
    box-sizing: border-box;
}

.modal-container-card-done .header .title-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    min-height: 220px;
    flex: 1 1 auto;
    margin: auto 0;
}

.modal-container-card-done .header .checkmark2{
    position: relative;
    width: 64px;
    height: 64px;
}

.modal-container-card-done .header .checkmark2 .line2{
    position: absolute;
    width: 7px;
    height: 18.81px;
    border-radius: 5px;
    transform: rotate(-45deg);
    background-color: #fff;
}

.modal-container-card-done .header .checkmark2 .line1{
    position: absolute;
    width: 7px;
    height: 30.81px;
    border-radius: 5px;
    transform: rotate(30deg);
    background-color: #fff;
}

.modal-container-card-done .header .checkmark2 .oval{
    width: 64px;
    height: 64px;
    border-radius: 1000px;
    background: #3EA6E8;
    z-index: -1;
}

.modal-container-card-done .header .title-container .title1{
    font-family: FSAlbertProBold;
    font-size: 28px;
    cursor: default;
}

.modal-container-card-done .header .title-container .subtitle1{
    font-family: FSalberPro;
    font-size: 16px;
    color: #ACB6C5;
    cursor: default;
    text-align: center;
}

.modal-container-card-done .header .card-container{
    position: relative;
    right:0;
    max-height: 220px;
    max-width: 70%;
    margin: auto 0;
    border-radius: 15px;
    overflow: hidden;
}

.modal-container-card-done .header .card-logo{
    position: relative;
    right:0;
    max-width: 100%;
    max-height: 220px;
    margin: auto 0;
}

@media (max-width:560px) {
    .modal-container-card-done .header{
        flex-direction: column;
        align-items: center;
    }

    .modal-container-card-done .header .title-container .subtitle1{
        margin-bottom: 20px;
    }
}