.pricing-page-container{
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    margin-top: 80px;
    max-width: 1400px;
    min-height: 200px;
}

.pricing-page-container .title{
    display: flex;
    height: 200px;
    width: 100%;
}

.pricing-page-container .title span{
    margin: auto;
    font-family: FSAlbertPro;
    font-size: 32px;
    text-align: center;
}

.pricing-page-container .cards-container{
    display: flex;
    justify-content: center;
    width: 100%;
    min-height: 500px;
}

.pricing-page-container .cards-container .my-card{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 20px;
    width: 80%;
    transition: 0.1s;
}

.pricing-page-container .cards-container .my-card:hover{
    box-shadow: 0 0 40px 3px #Fef4ea;
}

.pricing-page-container .cards-container .my-card .title-card{
    font-family: FSAlbertPro;
    font-size: 24px;
    margin-bottom: 20px;
    text-align: center;
}

.pricing-page-container .cards-container .my-card img{
    width: 320px;
    border-radius: 13px;    
}

.pricing-page-container .cards-container .my-card .info{
    font-family: FSAlbertPro;
    font-size: 16px;
    text-align: center;
    margin-top: 20px;
}

.pricing-page-container .cards-container .my-card a{
    color: rgb(94, 94, 245);
    margin: 10px 0;
    cursor: pointer;
}

.pricing-page-container .cards-container .my-card a:hover{
    text-decoration: underline blue;
}

.pricing-page-container .cards-container .my-card .button{
    padding: 5px 10px;
    background-color: #FDB913;
    border-radius: 13px;
    font-family: FSAlbertPro;
    font-size: 16px;
    cursor: pointer;
    opacity: 0;
    transition: 0.1s;
}

.pricing-page-container .cards-container .my-card:hover .button{
    opacity: 1;
}

.pricing-page-container .footer{
    display: flex;
    height: 300px;
    width: 100%;
    background: #EDF3FC;
    margin-top: 50px;
}

.pricing-page-container .footer .text-container{
    width: 60%;
    margin: auto;
    min-width: 350px;
    min-height: 150px;
}

.pricing-page-container .footer .text-container .text1{
    font-family: FSAlbertPro;
    width: 100%;
    font-size: 18px;
    line-height: 33px;
    text-align: center;
    color: #1A1919;
}

.pricing-page-container .footer .text-container .text2{
    margin-top: 64px;
    text-align: center;
}