.password-container{
    width: 100%;
    height: clamp(75px, 14vmin, 96px);
    min-width: 300px;
}

.password-repete-container{
    width: 100%;
    height: clamp(75px, 14vmin, 96px);
    min-width: 300px;
}