.modal-container-order-card{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 820px;
    min-height: 380px;
    max-width: 90%;
    border-radius: 15px;
    z-index: 12;
    padding: 20px 40px;
    background-color: #fff;
    background-image: url('/src/Images/Heads/full\ head.png');
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}

.modal-container-order-card .close-btn{
    position: absolute;
    right: 10px;
    top: 0;
    background-color: #fff;
    outline: none;
    border: none;
    font-weight: bold;
    font-size: 2rem;
}

.modal-container-order-card .header{
    display: flex;
    justify-content: space-between;
    width: 100%;
    min-height: 260px;
}

.modal-container-order-card .header .title-container{
    display: flex;
    flex-direction: column;
    margin-top: 40px;
}
.modal-container-order-card .header .title-container .title1{
    font-family: FSAlbertProBold;
    font-size: 28px;
    cursor: default;
}

.modal-container-order-card .header .title-container .subtitle1{
    font-family: FSalberPro;
    font-size: 16px;
    color: #ACB6C5;
    cursor: default;
}

.modal-container-order-card .header .card-container{
    position: relative;
    right:0;
    width: 320px;
    min-width: 320px;
    margin: auto 0;
    border-radius: 15px;
    overflow: hidden;
}

.modal-container-order-card .header .card-logo{
    position: relative;
    right:0;
    width: 320px;
    margin: auto 0;
}

.modal-container-order-card .body .continue-btn{
    height: clamp(40px, 8vmin, 60px);
    width: 150px;
    margin: 0 auto;
    margin-top: 10px;
}

.overlay{
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 11;
}

@media (max-width:700px) {
    .modal-container-order-card .header{
        flex-direction: column;
        align-items: center;
    }
}