.region-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: auto;
}

.region-container .left-dropdown{
    position: relative;
    width: 48%;
    height: clamp(75px, 14vmin, 96px);
}

.region-container .right-dropdown{
    width: 48%;
    height: clamp(75px, 14vmin, 96px);
    top: 0px;
}
@media (max-width: 768px) {
    .region-container{
        flex-direction: column;
    }

    .region-container .left-dropdown{
        width: 100%;
    }

    .region-container .right-dropdown{
        width: 100%;
    }
}

.province-container{
    height: 100%;
    width: 100%;
    min-width: 300px;
    box-sizing: border-box;
    height: clamp(75px, 14vmin, 96px);
    z-index: 10;
}

.city-container{
    width: 100%;
    min-width: 300px;
}