.big-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background: #fff;
    border-radius: 15px;    
    padding: 30px;
}

@media (max-width: 1370px) {
    .big-container{
        width: calc(100% - 45px);
    }
}

.order-card-container{
    display: flex;
    justify-content: space-between;
    width: 100%;

    background-color: #fff;
    border-radius: 15px;
}

.order-card-container .order-card-left{
    display: flex;
    height: 376px;
    width: 48%;
    min-width: 320px;
}
.order-card-container .order-card-left img{
    border-radius: 15px;
}

.balance-container{
    display: flex;
    align-items: center;
    width: 330px;
    height: 150px;
    box-shadow: 0px 0px 8px rgb(223, 221, 221);
    border-radius: 10px;
}
.balance-container .info-container{
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    justify-content: center;
}

.balance-container .info-container .sum{
    font-family: FSAlbertProBold;
    max-width: 100%;
    font-size: 28px;
    line-height: 22px;
    letter-spacing: -0.672px;
    color: #000000;
}

.balance-container .info-container .iban{
    font-family: FSAlbertPro;
    font-size: 16px;
    line-height: 19px;
    max-width: 100%;
    letter-spacing: -0.432px;
    color: #9F9F9F;
}

.order-card-container .order-card-right{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    min-height: 100px;
    width: 48%;
    min-width: 320px;
}

.order-card-container .order-card-right .icon-text-btn{
    display: flex;
    justify-content: center;
    width: 100%;
    height: 60px;
    border-radius: 10px;
    margin: 10px 0;
}

.order-card-container .order-card-right .copy-info{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;
    width: 100%;
    height: 60px;
    margin: 10px 0;
    border-radius: 10px;
    background-color: #a0cf67;
}

.order-card-container .order-card-right .copy-info span{
    color: #fff;
    font-family: FSAlbertPro;
    font-size: 0.85rem;
}
.order-card-container .order-card-right .extra-space{
    height: 80px;
    width: 80%;
    min-width: 320px;
}

.big-container .navigation-buttons{
    display: flex;
    justify-content: space-between;
    width: 300px;
    height: 50px;
}

.big-container .navigation-buttons button{
    border: none;
    background: #FDB931;
    width: 100%;
    height: 40px;
    border-radius: 10px;
}

.big-container .navigation-buttons button span{
    font-family: FSAlbertProBold;
}

.big-container .navigation-buttons .left-btn{
    width: 48%;
}

.big-container .navigation-buttons .right-btn{
    width: 48%;
}

.block-card-popup{
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    left: 50%;
    top: 50vh;
    transform: translate(-50%, -50%);
    width: 350px;
    min-width: 350px;
    min-height: 400px;
    padding: 20px;
    background: #fff;
    border-radius: 15px;
    z-index: 12;
    background-image: url('../Images/Heads/black\ head.png');
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
}

.block-card-popup .block-card-title{
    font-family: FSAlbertPro;
    font-size: 1.1rem;
    text-align: center;
    color: #fff;
}

.block-card-popup .pin-container{
    width: 40px;
    border: none;
    border-bottom: 2px solid #FDB931;
    background-color: transparent;
    outline: none;
    color: #fff;
    font-family: FSAlbertPro;
}

.block-card-popup .pin-container::placeholder{
    text-align: center;
    color: #fff;
}

.block-card-popup .btns-container-block{
    display: flex;
    width: 100%;
    justify-content: space-around;
    height: 40px;
}

.block-card-popup .btns-container-block .confirm{
    display: flex;
    width: 100px;
    height: 100%;
    background: #FDB931;
    cursor: pointer;
    border-radius: 10px;
}

.block-card-popup .btns-container-block .confirm span{
    margin: auto;
    font-family: FSAlbertProBold;
}

.block-card-popup .btns-container-block .deny{
    display: flex;
    width: 70px;
    height: 100%;
    background: #000;
    border-radius: 10px;
    cursor: pointer;
}

.block-card-popup .btns-container-block .deny span{
    margin: auto;
    font-family: FSAlbertProBold;
    color: #FDB931;
}

@media (max-width: 913px) {
    .big-container{
        width: 100%;
    }
}

@media (max-width: 750px) {
    .order-card-container{
        flex-direction: column;
        align-items: center;
    }

    .order-card-container .order-card-left{
        margin-bottom: 20px;
    }
}