.profession-container{
    width: 100%;
    height: clamp(75px, 14vmin, 96px);
    left: 738px;
    top: 348px;
    min-width: 300px;
}

.doubleDD-container{
    width: 100%;
    height: clamp(75px, 14vmin, 96px);
}

.ocupation-container{
    width: 100%;
    height: clamp(75px, 14vmin, 96px);
    min-width: 300px;
}

.purpose-container{
    width: 100%;
    height: clamp(75px, 14vmin, 96px);
    min-width: 300px;
}

.dd-input-container{
    width: 100%;
    height: clamp(75px, 14vmin, 96px);
}

.dd-container{
    width: 100%;
    height: 100%;
}

.membership-container{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    min-width: 300px;
}

.membership-container a{
    position: relative;
    width: 100%;
}